const MENU = "menu" as string;

/**
 * @description get token form localStorage
 */
export const getMenu = (): any | null => {
  if (window.localStorage.getItem(MENU)) {
    return JSON.parse(window.localStorage.getItem(MENU));
  } else {
    return [];
  }
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveMenu = (menu: any): void => {
  window.localStorage.setItem(MENU, JSON.stringify(menu));
};

/**
 * @description remove token form localStorage
 */
export const destroyMenu = (): void => {
  window.localStorage.removeItem(MENU);
};

export default { getMenu, saveMenu, destroyMenu };
